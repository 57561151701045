html, body, #root {
    height: 100%;
    margin: 0;
    color: #18181B;;
}
.style-form ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A1A1AA !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}
.text-lg-right{
    text-align: right;
}
.email-register ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A1A1AA !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}
.hover-btn-orange-to-black:hover{
    background-color: #18181B!important;
}
.hover-btn-black-to-orange:hover{
    background-color: #B91C1C!important;
}
.contact-form ::placeholder, .form-page ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A1A1AA !important;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A1A1AA !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #A1A1AA !important;
}
.trees-bg-bottom{
    width: 100%;
}
.font-sen-56-700 {
    font-family: 'Sen', sans-serif;
    letter-spacing: -2px;
    font-weight: 700;
    font-size: 56px;
    line-height: 70px;
    color: #27272A;
}

.font-sen-48-400 {
    font-family: 'Sen', sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -1px;
}

.font-sen-40-400 {
    font-family: 'Sen', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
}

.font-sen-40-700 {
    font-family: 'Sen', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    letter-spacing: -1px;
}

.font-sen-36-400 {
    font-family: 'Sen', sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
}

.font-sen-30-400 {
    font-family: 'Sen', sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 44px;
}

.font-sen-28-400 {
    font-family: 'Sen', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 44px;
}

.font-inter-24-700 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
}

.font-inter-36-700 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
}

.font-inter-30-600 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 30px;
}

.font-inter-24-400 {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 24px;
    line-height: 38px;
}

.font-inter-20-400 {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
}

.font-inter-20-700 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
}

.font-inter-18-700 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
}

.font-inter-16-400 {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.font-inter-14-400 {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.font-inter-12-400 {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
}

.bg-gray800 {
    background-color: #27272A;
}

.bg-gray700 {
    background-color: #3F3F46;
}

.bg-gray600 {
    background-color: #52525B;
}

.bg-gray300 {
    background-color: #D4D4D8;
}

.bg-gray200 {
    background-color: #E4E4E7;
}

.bg-red700 {
    background-color: #B91C1C;
}

.bg-red600 {
    background-color: #DC2626;
}

.text-grey-500 {
    color: #71717A;
}

.text-white {
    color: white;
}

.text-normal {
    color: #27272A;
}

.text-red-600 {
    color: #DC2626;
}

.wrapper {
    padding-top: 240px;
    margin-right: auto;
    margin-left: auto;
}

.pt-60 {
    padding-top: 60px;
}

.mt-60 {
    margin-top: 60px;
}

.big-logo {
    position: absolute;
    top: 0;
    width: 312px;
    transition: 1s;
}

.small-logo {
    position: absolute;
    top: 7px;
    left: 64px;
    width: 80px;
    transition: 1s;
}

.text-logo {
    position: absolute;
    color: white;
    font-family: 'Roboto', sans-serif;
    left: 145px;
    top: 15px;
    transition: 1s;
}

.first-test-logo {
    font-size: 16px;
    transition: 1s;
    margin-bottom: -4px;
}

.second-text-logo {
    font-size: 24px;
    font-weight: 700;
    transition: 1s;
}

.nav-scrolled .big-logo {
    top: -165px;

}

.nav-scrolled .small-logo {
    width: 54px;
}

.nav-scrolled .small-logo {
    width: 54px;
}

.nav-scrolled .text-logo {
    color: black;
    left: 130px;
}

.nav-scrolled .first-test-logo {
    font-size: 12px;
}

.nav-scrolled .second-text-logo {
    font-size: 20px;
}

.sticky-top {
    padding-top: 0 !important;
}

.w-screen {
    max-width: 1200px;
    margin: auto;
}

.navbar-nav .nav-link {
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    line-height: 36px;
    color: #27272A;
    text-align: center;
    padding-right: 60px !important;
}

.border-b-nav-link {
    display: block;
    height: 4px;
    width: 64px;
    background-color: #E4E4E7;
    margin: auto;
    margin-top: 4px;
}

.active .border-b-nav-link {
    background-color: #B91C1C;
}

.h-69px {
    height: 68px;
}

.footer {
    height: 100px;
    background-color: #27272A;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: white;
    position: relative;
}

.footer-link {
    color: white !important;
    border-bottom: 1px solid white;
    padding: 0 !important;
    margin-left: 32px;
}

.m-mountain {
    margin-top: -430px;
}


.carousel-indicators [data-bs-target] {
    background-color: #27272A !important;
    border-radius: 50%;
    width: 10px !important;
    height: 10px !important;
}

.carousel-indicators {
    right: auto !important;
    margin-left: 1% !important;
    bottom: 80px !important;
}

.carousel-height {
    height: 530px;
}

.carousel-control-next, .carousel-control-prev {
    display: none !important;
}

.input-group-text {
    border-radius: 0 !important;
    background-color: white !important;
    color: #A1A1AA !important;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    border: none;
}


.email-form .input-group {
    border: 1px solid #D4D4D8;
    min-width: 369px;
}

.h-50 {
    height: 50px !important;
}

.email-form .form-control {
    border: none;
}

.form-control {
    border-radius: 0 !important;
}

.btn-theme-orange {
    background-color: #DC2626 !important;
    border-radius: 0 !important;
    color: white !important;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 18px;
    height: 60px;
    min-width: 136px;
    border: none;
    display: table-cell;
}
.btn-theme-orange-small{
    min-width: auto;
    width: 87px;
    height: 38px;
}
.h-60 {
    height: 59px;
}

.m-mountain-box {
    margin-top: -180px;
}

.m-mountain-box-about {
    margin-top: -380px;
}

.navbar-nav {
    background-color: transparent !important;
}

.border-top-orange {
    width: 140px;
    height: 4px;
    margin-bottom: 16px;
    background: #DC251E;
}

.p-dark-box {
    padding: 48px;
}

.p-white-box {
    padding-top: 60px;
    padding-bottom: 60px;
}

.samAndTree {
    position: absolute;
    right: 0;
}

.style-form {
    display: flex;
    flex-direction: row;
}

.btn-theme-dark {
    min-width: 136px;
    height: 60px;
    background-color: #27272A!important;
    color: white!important;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    border-radius: 0;
    display: table-cell;
}
.btn-theme-dark-small {
    min-width: auto;
    width: 94px;
    height: 42px;
    font-size: 12px;
}

.btn-theme-dark:hover {
    background-color: #27272A;
    color: white;
}

.contact-form {
    padding: 50px;
    background-color: white;
    position: relative;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.m-trees-contact {
    margin-top: -230px;
}

.text-right {
    text-align: right;
}

.terms-list a {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    color: #27272A;
    text-decoration: none;
    padding: 10px;
    display: block;
    border-left: 4px solid transparent;
    cursor: pointer;
}

.terms-list .active {
    border-left: 4px solid #DC251E;
}

.sidebar-terms {
    padding-top: 100px;
    max-width: 350px;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    height: 100%;
}

.main-terms {
    padding-top: 100px;
    max-width: 750px;
    margin-left: 450px;
}

.terms-page {
    display: flex;
    justify-content: space-between;
}

.m-mountain-box-thanks-free {
    margin-bottom: -320px;
    margin-top: 110px;
}

.email-register {
    border: 2px solid #27272A;
    width: 369px;
    position: relative;
}

.email-register input {
    height: 56px;
    width: 100%;
    border: none;
    padding-left: 53px;
    color: #27272A;
    background-color: transparent;
}
.report-register input{
    height: 42px;
}
.report-register {
    border: 1px solid #D4D4D8;
}

.bg-white .email-register input {
    color: white;
}

.email-register .icon {
    color: #A1A1AA;
    position: absolute;
    top: 15px;
    left: 12px;
}
.report-register .icon{
    top: 8px;
}
.email-register {
    background-color: white;
}
.report-modal .modal-content{
    min-height: 720px;
}
.bg-white .email-register {
    background-color: #27272A;
}

.border-left-orange {
    border-left: 8px solid #DC251E;
}

.bg-img {
    background-size: cover;
    background-position: center;
}

.p-box-report {
    padding: 50px;
}

.number-report {
    font-family: 'Sen', sans-serif;
    font-size: 104px;
}
.max-w-sam{
    max-width: 300px;
}
.number-report sup {
    font-size: 40px;
    color: #E4E4E7;
    vertical-align: super;
}

.bg-form {
    background-color: #F5F5F5;
}
.min-h-screen{
    min-height: 60vh!important;
}
.min-h-90{
    min-height: 90vh!important;
}
.coming-soon{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: rgb(1,1,1,.5);
}
.max-w-form {
    max-width: 992px;
    margin: auto;
}

.required-star {
    color: red;
    padding-right: 4px;
}

.incomplete {
    background-color: #F19C9C;
    padding: 8px 16px;
    color: #A30000;
}
.cursor-pointer{
    cursor: pointer;
}
.p-form {
    padding: 40px;
}

.p-32 {
    padding: 32px;
}

.linkToHome:hover {
    text-decoration: underline;
}

.form-shadow {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.hover-pointer .form-check-input, .hover-pointer .form-check-label {
    cursor: pointer;
}

.nav-second {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.wrapper-second {
    padding-top: 60px;
    margin-right: auto;
    margin-left: auto;
}

.method-img-sub {
    padding-top: 17px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 62px;
    background-color: #000000;
    color: white;
    text-align: center;
    opacity: 0.8;
    ont-family: 'Inter';
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
}
.mt-mountain {
    margin-top: -25%;
    margin-bottom: -10%;
}
.mt-mountain-submit {
    margin-top: -30%;
    margin-bottom: -18%;
}

.m-mountain-home {
    margin-top: -30%;
    margin-bottom: -23%;
    min-height: 700px;
}

.m-mountain-about {
    margin-bottom: -16%;
    margin-top: -23%;
}
.m-mountain-contact {
    margin-bottom: -16%;
    margin-top: -25%;
}

.m-mountain-strategy-pdf {
    margin-top: -28%;
    margin-bottom: -15%;
}

.m-mountain-methods {
    margin-top: -28%;
    margin-bottom: -15%;
}

.m-mountain-report {
    margin-top: -28%;
    margin-bottom: -15%;
}

.modal-header {
    border-bottom: none;
}

.modal-body {
    padding: 48px;
}

.mt-some {
    margin-top: 100px;
}

.mt-48 {
    margin-top: 48px;
}

.p-black-bottom {
    padding-top: 80px;
    padding-bottom: 80px;
}

.mt-32 {
    margin-top: 32px;
}

.form-check-input {
    height: 24px;
    width: 24px;
}

.form-check-label {
    margin-left: 16px;
    padding-top: 3px;
}

.w-free-img {
    width: 100%;
}
.mt-coming{
    margin-top: 100px;
}
.container-email {
    display: flex;
    width: 100%;
}
.w-light-box{
    width: 100%;
}
.d-just-mobile{
    display: none;
}
.disabled-link{
    pointer-events: none;
}
.mt-conditions{
    margin-top: 35px;
}
.mt-inner-conditions{
    margin-top: 12px;
}
.special-offer-img{
    width: 100%;
}
.p-gr-box{
    padding: 24px 11px;
}
.w-handshake{
    width: 100%;
}
.modal-report .modal-dialog{
    max-width: 700px;
}
@media (max-width: 768px) {
    .text-lg-right{
        text-align: left;
    }
    .form-check{
        display: flex;
    }
    .p-black-bottom {
        padding-top: 27px;
    }
    .w-handshake{
        width: 60%;
    }
    .p-special-offer{
        padding: 24px 0;
    }
    .mt-inner-conditions{
        margin-top: 12px;
    }
    .mt-conditions{
        margin-top: 24px;
    }
    .sidebar-terms{
        display: none;
    }
    .main-terms{
        padding-top: 25px;
    }
    .d-just-mobile{
        display: block;
    }
    .navbar-nav .nav-link{
        padding-right: 0!important;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .ml-sm-auto {
        margin-left: auto;
    }

    .mt-smm-1 {
        margin-top: 5px;
    }

    .btn-theme-orange {
        max-width: 175px;
    }
    .pb-smm-30{
        padding-bottom: 30px;
    }
    .p-form {
        padding: 24px;
    }
    .font-sen-28-400{
        line-height: 38px;
    }
    .container-email {
        flex-direction: column;
    }

    .w-free-img {
        width: 60%;
    }
    .trees-bottom{
        display: none;
    }
    .mt-some {
        margin-top: 50px;
    }

    .p-smm-1 {
        padding: 10px;
    }

    .font-inter-24-400 {
        line-height: 26px;
        font-size: 18px;
    }

    .big-logo {
        top: -165px;
    }

    .second-text-logo {
        font-size: 20px;
    }

    .first-test-logo {
        font-size: 12px;
    }

    .text-logo {
        color: black;
        left: 66px !important;
    }

    .small-logo {
        width: 54px;
        left: 0;
    }

    .wrapper {
        padding-top: 80px;
    }

    .text-smm-left {
        text-align: left !important;
    }

    .font-sen-56-700, .font-sen-48-400 {
        font-size: 36px;
    }
    .footer{
        height: 130px;
        padding: 25px 0;
    }
    .m-smm-0{
        margin: 0;
    }
    .contact-form{
        padding: 32px;
    }
    .pb-smm-60{
        padding-bottom: 60px;
    }
    .w-light-box{
        width: 150px;
    }
    .p-smm-15rem{
        padding: 1.5rem;
    }
    .w-check{
        width: 80px;
    }
    .font-sen-40-700 {
        font-size: 32px;
        line-height: 43px;
    }
    .p-method{
        padding: 0 26px;
    }
    .mb-smm-3{
        margin-bottom: 30px;
    }
    .m-trees-contact{
        display: none;
    }
    .d-smm-none{
        display: none!important;
    }
    .font-sen-48-400 {
        line-height: 48px;
    }

    .font-sen-56-700 {
        line-height: 45px;
    }

    .wrapper-second {
        padding-top: 30px;
    }

    .p-box-report {
        padding: 25px;
    }
    .number-report sup{
        vertical-align: middle;
    }
    .font-sen-40-400{
        font-size: 36px;
        line-height: 50px;
    }
    .special-offer-img{
        width: 80%;
    }
    .email-register {
        width: auto;
    }

    .terms-page {
        flex-direction: column;
    }

    .sidebar-terms {
        max-width: initial;
        overflow: initial;
        position: initial;
        height: auto;
    }

    .main-terms {
        max-width: initial;
        margin-left: auto;
    }

    .footer {
        text-align: center;
    }
    .justify-content-smm-center{
        justify-content: center;
    }
    .text-small-center {
        text-align: center;
    }

    .mt-mountain, .m-mountain-contact, .m-mountain-report, .m-mountain-methods, .m-mountain-strategy-pdf, .m-mountain-about, .m-mountain-home, .mt-mountain-submit {
        display: none;
    }

    .px-small-3 {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    .mt-small-3 {
        margin-top: 30px;
    }

    .samAndTree {
        display: none;
    }

    .p-dark-box {
        padding: 28px 0;
    }

    .style-form {
        flex-direction: column;
    }

    .input-group {
        min-width: auto !important;
    }

    .carousel-indicators {
        left: 0;
        right: 0 !important;
        margin-left: auto !important;
        margin-right: auto;
        width: 100px;
        bottom: 0 !important;
    }

    .carousel-height {
        height: 695px;
    }

    .m-mountain, .m-mountain-box, .m-mountain-box-about, .m-mountain-about {
        margin-top: 0;
    }

    .p-xs-4 {
        padding: 20px;
    }

    .navbar-nav {
        position: relative;
        background-color: white !important;
        padding: 20px 0;
    }

}
