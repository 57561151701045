@font-face {
    font-family: 'OpenSans-Light';
    src: url("font/OpenSans-Light.ttf");
}

@font-face {
    font-family: 'OpenSans';
    src: url("font/OpenSans.ttf");
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url("font/OpenSans-Bold.ttf");
}

@font-face {
    font-family: 'Montserrat';
    src: url("font/Montserrat.ttf");
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url("font/Montserrat-Bold.ttf");
}


@font-face {
    font-family: 'Calibri-Light';
    src: url("font/Calibri-Light.ttf");
}

@font-face {
    font-family: 'Calibri';
    src: url("font/Calibri.ttf");
}

@font-face {
    font-family: 'Calibri-Bold';
    src: url("font/Calibri-Bold.ttf");
}

@font-face {
    font-family: 'Smooch';
    src: url("font/Smooch.ttf");
}


@font-face {
    font-family: 'Hebrew';
    src: url("font/OpenSansHebrew-Regular.ttf");
}
@font-face {
    font-family: 'Hebrew-Light';
    src: url("font/OpenSansHebrew-Light.ttf");
}

@font-face {
    font-family: 'Hebrew-Bold';
    src: url("font/OpenSansHebrew-Bold.ttf");
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url("font/Montserrat-SemiBold.ttf");
}

nav {
    background-color: #353535;
}
.frame8-2 .text-bold{
font-family: OpenSans-Bold;
}
.logo {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.frame1-1 {
    line-height: 60px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.p-35 {
    padding: 35px;
}

.btn-danger {
    -webkit-appearance: none;
    border-radius: 0;
    background-color: #ff002e!important;
    min-width: 147px;
    font-size: 20px;
    font-weight: 800;
    padding: 12px;
    font-family: OpenSans-Bold;
}

.box-buyNow b {
    font-family: OpenSans-Bold;
}

.frame4-4 b {
    font-family: OpenSans-Bold;
}
.font-calibri{
    font-family: Calibri-Light;
}
.font-calibri b{
    font-family: Calibri-Bold;
}
.titleSuccess {
    font-family: Hebrew-Light;
    font-size: 16px;
    font-weight: 300;
    text-align: right;
    color: #fff;
}
.pr-60{
    padding-right: 60px;
}
.signature {
    font-family: Smooch;
    font-size: 36px;
    text-align: right;
    color: #fff;
}

.ml-15 {
    margin-left: 15px;
}

.img-frame2 {
    background-image: url("./img/img1@3x.png");
    background-size: cover;
    height: 100%;
}

.img-frame3 {
    background-image: url("./img/tone_img1@3x.png");
    background-size: cover;
    height: 100%;
}

.box-buyNow {
    font-family: OpenSans;
    font-size: 20px;
    color: #1f1f1f;
}

.img-frame1 {
    background-image: url("./img/banner.png");
    background-size: cover;
    padding: 70px 180px;
}

.p-sm-0 {
    padding: 0;
}

.main-container {
    margin: auto;
    position: relative;
    z-index: 1;
    max-width: 960px;
}

.color-yellow {
    color: #E3B938;;
}

.frame1-2 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    text-align: center;
    color: #FFFFFF;
}

.frame1-3 {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 68px;
    text-align: center;
    color: #FF002E;

}

.frame2-1 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    color: #1F1F1F;
}

.w-147 {
    width: 147px;
}

.font-weight-bold {
    font-weight: bold;
}

.frame3-1 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

    color: #1F1F1F;
    border-left: 5px solid red;
    padding-left: 20px;
}

.frame4-1 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    color: #E3B938;
}

.frame4-2 {
    font-family: 'Montserrat-bold';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #E3B938;
}

.frame4-3 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
}

.frame8-2 b {
    font-family: 'OpenSans-Bold';
}

.frame4-4 {
    font-family: 'Calibri-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

    color: #F8F8F8;
}

.bg1-gray {
    background: #1F1F1F;
}

.bg2-gray {
    background: #353535;
}

.text-right {
    text-align: right;
}

.frame4-5 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

    text-align: right;
    color: #F8F8F8;
}

.pl-0 {
    padding-left: 0 !important;
}

.frame5-1 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

    color: #000000;
}

.frame5-2 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    color: #000000;
}

.frame6-1 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    color: #FFFFFF;
}

.bg3-gray {
    background: #353535;
}

.frame7-1 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    color: #1F1F1F;
    display: flex;
    justify-content: space-between;
}

.frame7-2 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding: 0 0 8px 0;
    color: #1F1F1F;
}

.frame7-3 {
    font-family: 'Hebrew';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: #353535;
}

.frame7-4 {
    padding: 35px;
    border-top: 2px solid black;
}

.border-left {
    border-left: 2px solid black;
}

.frame8-1 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

    color: #1F1F1F;
}

.frame8-2 {
    font-family: 'OpenSans';
    font-style: normal;
    font-size: 24px;
    color: #1F1F1F;
}

.frame9-1 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

    text-align: center;
    color: #E3B938;
}

.frame9-2 {
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    text-align: center;
    color: #E3B938;

}

.frame10-1 {
    font-family: 'Hebrew';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: #FFFFFF;
}

.frame10-2 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;

    color: #FFFFFF;
}

.border-yellow {
    border: 3px solid #E3B938;
}

.frame11-1 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

    text-align: center;
    color: #F8F8F8;

}

.frame12-1 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

}

.frame12-2 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

}

.frame12-3 {
    font-family: 'Calibri-Light';
    font-style: normal;
    font-size: 18px;

}

.frame12-3 b {
    font-family: Calibri-Bold;
}

.frame12-4 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

}

.fram12-5 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

}

.frame12-6 {
    font-family: 'OpenSans-Bold';
    font-style: normal;
    font-size: 20px;

}

.frame12-7 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;

}

.frame13-1 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

}

.frame13-2 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

}

.frame13-3 {
    font-family: 'Hebrew';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: #353535;

}

.bg4-gray {
    background: #F8F8F8;
}

.frame13-4 {
    font-family: 'OpenSans-Light';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;

}

.box-guide .d-flex {
    padding-left: 0 !important;
    align-items: center;
}

.box-guide .frame17-2 {
    margin-top: 8px;

}
.white-box-guide .frame17-2{
    color: #666666;
}
.frame-146 input, .form-select {
    height: 48px;
    padding: 8px 16px;
    border: solid 1px #b9b9b9;
    background-color: #f8f8f8;
}

.frame13-5 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

}

.frame14-1 {
    font-family: 'Calibri-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #1F1F1F;
}

.frame14-1 b {
    font-family: 'Calibri-Bold';
}

.frame14-2 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    border-bottom: 4px solid black;
    color: #1F1F1F;
}

.frame14-3 {
    font-family: 'OpenSans-Light';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;

}

.text-bold {
    font-weight: bold;
}

.frame15-1 {
    font-family: 'OpenSans-Light';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    border: 1px solid black;
}

.frame15-2 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;

    color: #FFFFFF;

}

.frame15-3 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

    color: #FFFFFF;
}

.frame16-1 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

    color: #FFFFFF;
    padding: 20px;
    border: 3px solid #E3B938;
    margin: 30px 15px;
    margin-left: 0;
}

.frame16-2 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

    padding: 20px;
    border: 3px solid #E3B938;
    margin: 30px 15px;
    margin-left: 0;
    color: #FFFFFF;
}

.frame16-3 {
    font-family: 'Hebrew';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: #FFFFFF;
    margin-top: 20px;
}
.frame16-3 b{
    font-family: Hebrew-Bold;
}
.font-opensans{
    font-family: OpenSans;
}
.font-opensans b{
    font-family: OpenSans-Bold;
}
.frame16-4 {
    font-family: 'Calibri-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #1F1F1F;
}

.frame16-4 b {
    font-family: Calibri-Bold;
}

.frame17-1 {
    font-family: 'Hebrew';
    font-style: normal;
    font-size: 20px;
}

.frame17-2 {
    font-family: 'Hebrew';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;

}

.frame17-3 {
    font-family: 'Calibri-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

    color: #1F1F1F;
}

.frame17-3 b {
    font-family: Calibri-Bold;
}

.frame17-4 {
    font-family: 'OpenSans-Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #1F1F1F;
}

.frame18-1 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    color: #E3B938;
}

.frame18-2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #E3B938;
}

.frame18-3 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    color: #FFFFFF;
}

.frame18-4 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

    color: #F8F8F8;
}

.frame18-5 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

    color: #F8F8F8;
}

.frame19-1 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;

    color: #E3B938;
}

.frame19-1 b {
    font-family: 'OpenSans-Bold';
}

.frame19-2 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

    color: #FFFFFF;
}

.text-white {
    color: white !important;
}

.frame19-3 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    color: #FFFFFF;
}

.frame19-4 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

    color: #FFFFFF;
}

.frame19-5 {
    font-family: 'Hebrew';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: #FFFFFF;
}

.frame20-1 {
    font-family: 'Hebrew-Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 16px;
}

.p-40 {
    padding: 40px;
}

.frame20-2 {
    font-family: 'Calibri-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

}

.frame21-1 {
    font-family: 'Calibri-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

}

.frame21-2 {
    font-family: 'Calibri-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;

}
.frame21-2 b{
    font-family: Calibri-Bold;
}
.border-gray {
    border: 1px solid #353535;
}

.frame21-3 {
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
}
.frame21-5 b{
    font-family: OpenSans-Bold;
}
.dark-box .frame17-1{
    font-family: Hebrew;
}
.dark-box .frame17-2{
    font-family: Hebrew;
    color: #dbdbdb;
}
.frame21-4 {
    margin-top: 8px;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

}
.vertically-center{
    justify-content: center;
    align-items: center;
}
.p-white-box{
    padding: 24px 40px;
}
.text-white2 {
    color: #F8F8F8;
}

.frame21-5 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

}

.border-red {
    border: 1px solid #FF002E;
}

.frame22-1 {
    font-family: 'OpenSans-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;

}

.frame22-2 {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.border-green {
    border: 1px solid #6FCF97;
}

.frame22-3 {
    font-family: 'Calibri-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
}

.border-yellow1 {
    border: 1px solid #E3B938;
}

.p-relative {
    position: relative;
}

.frame-last {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #F8F8F8;

}

.frame20-3 {
    font-family: 'Calibri-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
}

.d-phone {
    display: none;
}

.d-desktop {
    display: block;
}
.font-18{
    font-size: 18px;
}
.p-24{
    padding: 24px;
}
.mt-24{
    margin-top: 24px;
}
@media screen and (max-width: 768px) {
    .m-xs-0 {
        margin: 0;
    }

    .justify-content-xs-center {
        justify-content: center !important;
    }

    .frame1-1 {
        font-size: 36px;
    }

    .frame1-2 {
        font-size: 14px;
    }

    .frame1-3 {
        font-size: 56px;
    }

    .d-phone {
        display: initial;
    }

    .d-desktop {
        display: none;
    }

    .frame7-4 {
        padding: 20px;
    }

    .p-35 {
        padding: 20px;
    }

    .text-xs-center {
        text-align: center;
    }

    .mt-xs-3 {
        margin-top: 20px;
    }

    .img-frame1 {
        background-image: url("./img/Banner_m.png");
        padding: 40px;
    }

    .border-xs-none {
        border: none;
    }

    .p-xs-0 {
        padding: 0;
    }

    .p-40 {
        padding: 20px;
    }
    .d-xs-block{
        display: block!important;
    }
    .frame1-1{
        line-height: 48px;
    }
    .pr-60{
        padding: 0;
    }
}
